import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import { currentDate } from "utils/currentDate";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { useParams, useHistory } from "react-router";
import { createTruenat } from "store/actions";
import { getStocks } from "store/actions";
import { getTruenats } from "store/actions";
import { getPatient } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";

const TruenatCreate = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const router = useHistory();
  const { patientId } = useParams();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { status, truenat } = useSelector((state) => state);
  const { truenats } = useSelector((state) => state.truenat);
  const { patient } = useSelector((state) => state.patient);

  useEffect(() => {
    dispatch(getStocks());
    dispatch(getTruenats(patientId));
    dispatch(getPatient(patientId));
  }, [dispatch, patientId]);

  useEffect(() => {
    if (!truenats?.length > 0) {
      setValue("specimen_received_date", patient?.registration_date);
    }
  }, [setValue, patient.registration_date, truenats.length]);

  async function onSubmit(data) {
    setLoading(true);
    await dispatch(
      createTruenat({
        patient_id: patientId,
        specimen_received_date: data.specimen_received_date,
      })
    );
    setLoading(false);
  }

  const formReset = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (status.success) {
      formReset();
    }

    return () => formReset();
  }, [status.success, formReset]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>New Specimen</h3>
              <span>
                Patient - {`${patient?.name}  ( ${patient?.presumptive_code} )`}
              </span>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomDateInput
                    id="specimen_received_date"
                    label="Specimen Received Date"
                    register={{
                      ...register("specimen_received_date", {
                        required: "Specimen Received Date is required!",
                      }),
                    }}
                    placeholder="Select Specimen Received Date"
                    errors={errors}
                    isRequired={true}
                    max={currentDate()}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-right">
              <Button color="primary" size="sm" type="submit">
                {loading ? "Loading" : "Save"}
              </Button>
              {!!Object.keys(truenat.createdTruenat).length && (
                <Button
                  size="sm"
                  color="success"
                  onClick={() => {
                    if (patient) {
                      router.push({
                        pathname: `/admin/truenat-detail/${truenat.createdTruenat.id}`,
                        state: {
                          patientId: patient?.id,
                          index: truenat?.truenats?.length - 1,
                        },
                      });
                    }
                  }}
                >
                  Detail
                </Button>
              )}
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default TruenatCreate;
